import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import { Room } from "colyseus.js";
import 'babylonjs-loaders'
// import * as NippleJS from 'nipplejs';


import Menu from "./menu";
import RoomManager from './roomManager';
import Hero from './hero';
import Instruction from './instruction';
import CharacterController from './characterController';
import { getUrlParams, isMobile } from './utils';
// digital assets
import heroModel from "../assets/glb/girl_v2.glb";


const GROUND_SIZE = 500
export default class Game {
    private canvas: HTMLCanvasElement;
    private engine: BABYLON.Engine;
    private scene: BABYLON.Scene;
    private camera: BABYLON.ArcRotateCamera;
    private light: BABYLON.Light;

    private room: Room<any>;
    private playerEntities: { [playerId: string]: Hero } = {};
    private playerAnimations: {[playerId: string]: any} = {};
    private playerNextPosition: { [playerId: string]: BABYLON.Vector3 } = {};
    private playerNextRotation: { [playerId: string]: BABYLON.Quaternion } = {};
    
    private tvMesh: null | BABYLON.Mesh;
    private playerName: string

    constructor(canvas: HTMLCanvasElement, engine: BABYLON.Engine, room: Room<any>, playerName: string) {
        this.canvas = canvas;
        this.engine = engine;
        this.room = room;
        this.tvMesh = null
        this.playerName = playerName
    }

    createGround(): void {
        // Create ground plane
        const plane = BABYLON.MeshBuilder.CreatePlane("plane", { size: GROUND_SIZE }, this.scene);
        plane.position.y = 0;
        plane.rotation.x = Math.PI / 2;

        let floorPlane = new BABYLON.StandardMaterial('floorTexturePlane', this.scene);
        floorPlane.diffuseTexture = new BABYLON.Texture('./public/ground.jpg', this.scene);
        floorPlane.backFaceCulling = false; // Always show the front and the back of an element

        let materialPlane = new BABYLON.MultiMaterial('materialPlane', this.scene);
        materialPlane.subMaterials.push(floorPlane);

        plane.material = materialPlane;
    }

    initPlayers(): void {
        this.room.state.players.onAdd(async (player, sessionId) => {
            const isCurrentPlayer = (sessionId === this.room.sessionId);
            // const {hero, heroResult} = await this.addHero()
            const hero = new Hero(this.scene, this.camera, player.name);
            await hero.load();
            hero.setPosition(new BABYLON.Vector3(player.positionX, player.positionY, player.positionZ))
            // hero.position.set(player.positionX, player.positionY, player.positionZ)
            let quaternionObject = JSON.parse(player.quaternion);
            let newQuaternion = new BABYLON.Quaternion(quaternionObject.x, quaternionObject.y, quaternionObject.z, quaternionObject.w);
            hero.setRotation(newQuaternion)
            // hero.rotationQuaternion = newQuaternion
            this.playerEntities[sessionId] = hero;
            this.playerNextPosition[sessionId] = hero.mesh.position.clone();
            this.playerNextRotation[sessionId] = hero.mesh.rotationQuaternion.clone();
            // this.playerAnimations[sessionId] = heroResult.animationGroups
            const animationGroups = this.playerAnimations[sessionId]
            if (isCurrentPlayer) {
                const characterController = new CharacterController(this.scene, this.room, this.playerNextPosition, this.playerNextRotation, hero)
                const currentHero = this.playerEntities[sessionId].mesh
                // const cameraAnchor = hero.root.meshes.find((obj: any) => obj.name === 'cameraPosition')
                const cameraAnchor = hero.thirdPersonView
                
                this.camera.parent = currentHero;
                this.camera.setTarget(new BABYLON.Vector3(cameraAnchor.position.x, cameraAnchor.position.y - .5, cameraAnchor.position.z + 1.5));
                this.camera.position = new BABYLON.Vector3(cameraAnchor.position.x, cameraAnchor.position.y, cameraAnchor.position.z);
                // this.actionsHero(hero)
                characterController.actionsHero()
                if(isMobile()) characterController.initJoystic()
                characterController.activateControls()
            } else {
                this.createNameTag(this.scene, player.name, hero.mesh as BABYLON.Mesh)
            }

            // update local data
            player.onChange(() => {
                this.playerAnimations[sessionId] = player.animation
                if(isCurrentPlayer) return
                this.playerNextPosition[sessionId].set(player.positionX, player.positionY, player.positionZ);
                let quaternionObject = JSON.parse(player.quaternion);
                let newQuaternion = new BABYLON.Quaternion(quaternionObject.x, quaternionObject.y, quaternionObject.z, quaternionObject.w);
                this.playerNextRotation[sessionId] = newQuaternion
            });
        });

        this.room.state.players.onRemove((player, playerId) => {
            this.playerEntities[playerId].mesh.dispose();
            delete this.playerEntities[playerId];
            delete this.playerNextPosition[playerId];
            delete this.playerNextRotation[playerId];
            delete this.playerAnimations[playerId]
        });

        this.room.onLeave(code => {
            this.gotoMenu();
        })
    }
    
    

    createNameTag(scene: BABYLON.Scene, name: string, hero: BABYLON.Mesh): void {
        const advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

        const nameText = new GUI.TextBlock();
        nameText.text = name;
        nameText.color = "white";
        nameText.fontSize = 24;

        const plane = BABYLON.MeshBuilder.CreatePlane("nameTag", { size: 2 }, scene);
        plane.parent = hero;
        plane.position.y = 2;
        
        // plane.lookAt(this.camera.position)
        // plane.rotation.y = Math.PI/4
        plane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

        const nameTexture = GUI.AdvancedDynamicTexture.CreateForMesh(plane);
        nameTexture.addControl(nameText);
    }

    

    bootstrap(): void {

        if(isMobile()) document.getElementById('mobileUI').style.display = 'block'
        this.room.send('name', {name: this.playerName})
        this.scene = new BABYLON.Scene(this.engine);
        this.light = new BABYLON.HemisphericLight("pointLight", new BABYLON.Vector3(), this.scene);
        this.camera = new BABYLON.ArcRotateCamera(
            "my first camera",
            0,
            0,
            0,
            new BABYLON.Vector3(0, 0, 0),
            this.scene
        );

        // This targets the camera to scene origin
        this.camera.setTarget(BABYLON.Vector3.Zero());

        // This attaches the camera to the canvas
        this.camera.attachControl(this.canvas, true);
        this.camera.lowerRadiusLimit = 2;
        this.camera.upperRadiusLimit = 3;
        this.camera.wheelDeltaPercentage = 0.01;

        this.camera.lowerAlphaLimit = -Math.PI/2
        this.camera.upperAlphaLimit = -Math.PI/2

       
        // this.scene.gravity = new BABYLON.Vector3(0, -0.9, 0);

        // Enable Collisions
        // this.scene.collisionsEnabled = true
        new RoomManager(this.scene, this.engine, this.playerName)
        new Instruction(this.room, this.playerName)
        
        this.initPlayers();

        this.doRender();
    }

    private gotoMenu() {
        this.scene.dispose();
        const menu = new Menu('renderCanvas');
        const roomName = getUrlParams('r')
        menu.createMenu(true, roomName);
    }

    private doRender(): void {
        // constantly lerp players
        this.scene.registerBeforeRender(() => {
            for (let sessionId in this.playerEntities) {
               
                const entity = this.playerEntities[sessionId];
                entity.playAnimation(this.playerAnimations[sessionId]);
                const targetPosition = this.playerNextPosition[sessionId];
                const targetRotation = this.playerNextRotation[sessionId];
                entity.mesh.position = BABYLON.Vector3.Lerp(entity.mesh.position, targetPosition, 0.3);
                  entity.mesh.rotationQuaternion = BABYLON.Quaternion.Slerp(entity.mesh.rotationQuaternion!, targetRotation, 0.1);
                entity.mesh.rotationQuaternion = this.playerNextRotation[sessionId]
                // console.log("111111", entity.mesh.rotationQuaternion)
                entity.mesh.position = this.playerNextPosition[sessionId]
                // if (BABYLON.Vector3.Distance(entity.mesh.position, targetPosition) < 0.01) {
                
                // }
            }
        });

        const fps = document.createElement('div')
        document.body.appendChild(fps)
        fps.style.cssText = "color: lightblue; font-family: Arial; background-color: black; font-size: 20px; padding: 10px; position: absolute; top: 55px; left: 0;";

        // Run the render loop.
        this.engine.runRenderLoop(() => {
            fps.innerHTML = this.engine.getFps().toFixed() + ' ' + 'fps'
            this.scene.render();
        });

        // The canvas/window resize event handler.
        window.addEventListener('resize', () => {
            this.engine.resize();
        });
    }
}
