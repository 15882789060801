import roomModel_1 from "../assets/glb/room_1.glb";
import roomModel_2 from "../assets/glb/room_2.glb";
import roomModel_3 from "../assets/glb/room_3.glb";
import roomModel_4 from "../assets/glb/room_4.glb";
import roomModel_5 from "../assets/glb/room_5.glb";
import { getUrlParams, isMobile } from "./utils";

const ROOM_MODELS = {
    "room_1": roomModel_1,
    "room_2": roomModel_2,
    "room_3": roomModel_3,
    "room_4": roomModel_4,
    "room_5": roomModel_5,
}
const ROOM_SCALE = 12
export default class TextureManager {
    private scene: any;
    private engine: any;
    private roomNames: string[];
    private roomModels: string[];
    private currentRoomIndex: number;
    private tvMesh: any;
    private rooms: Map<string, BABYLON.AbstractMesh[]>;
    private colliders: Map<string, BABYLON.AbstractMesh>;
    private furniture: BABYLON.AbstractMesh[] | null;
    private roomsWithFurniture: Set<string>;
    private numRooms: number;
    private playerName: string
    

    constructor(scene: any, engine: any, playerName: string) {
        this.scene = scene;
        this.engine = engine;
        this.rooms = new Map<string, BABYLON.AbstractMesh[]>();
        this.colliders = new Map<string, BABYLON.AbstractMesh>();
        this.furniture = null;
        this.roomsWithFurniture = new Set<string>(["room1", "room2"]);
        this.roomNames = ['room1'];
        this.roomModels = [ROOM_MODELS[getUrlParams('r')]];
        this.currentRoomIndex = 0;
        this.numRooms = 8;
        this.playerName = playerName
        this.init();
    }

    private init(): void {
        const roomsEl = document.getElementById('rooms')
        const roomList: HTMLElement = document.querySelector('.roomList')
        const roomItems: NodeListOf<HTMLElement> = document.querySelectorAll('.room-item')
        const currentRoomEl: HTMLElement = document.querySelector('.currentRoom')
        const room = getUrlParams('r')

        const activeRoomMobile = (room: number) => {
            currentRoomEl.innerHTML = String(room)
            roomItems.forEach((item) => {
                const roomId = item.dataset.roomId
                for(let i = 0; i < roomItems.length; i++){
                    item.classList.remove('active')
                }
                if(Number(roomId) === room)
                    item.classList.add('active')
            })
        }

        switch(room){
            case 'room_1':
                activeRoomMobile(1)
                break
            case 'room_2':
                activeRoomMobile(2)
                break
            case 'room_3':
                activeRoomMobile(3)
                break
            case 'room_4':
                activeRoomMobile(4)
                break
            case 'room_5':
                activeRoomMobile(5)
                break
            default:
                break
        }
        this.showLoadingScreen()
        this.loadModels().then(() => {
            window.addEventListener("keydown", (event) => {
                if (event.code === 'Digit1') {
                    this.changeLocation(1)
                }
                if (event.code === 'Digit2') {
                    this.changeLocation(2)
                }
                if (event.code === 'Digit3') {
                    this.changeLocation(3)
                }
                if (event.code === 'Digit4') {
                    this.changeLocation(4)
                }
                if (event.code === 'Digit5') {
                    this.changeLocation(5)
                }
            });
            if(isMobile()){
                let showRoomList = false
                roomsEl.style.display = 'flex'
                roomsEl.addEventListener('click', () => {
                    console.log('container')
                    showRoomList = !showRoomList
                    if(showRoomList){
                        roomList.style.display = 'flex'
                    }else{
                        roomList.style.display = 'none'
                    }
                })
                roomItems.forEach((item) => {
                    item.addEventListener('click', (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        const roomId = item.dataset.roomId
                        if(!item.classList.contains('active')){
                            currentRoomEl.innerHTML = roomId
                            this.changeLocation(Number(roomId))
                            for(let i = 0; i < roomItems.length; i++){
                                item.classList.remove('active')
                            }
                            item.classList.add('active')
                        }
                        roomList.style.display = 'none'
                        showRoomList = !showRoomList
                    })
                })
            }
            this.showRoom('room1')
            this.hideLoadingScreen()
        });
        
    }

   

    changeLocation(roomNum: number){
        switch (roomNum) {
            case 1:
                window.location.href = `https://coloring3d.aestar.com.ua/projects/meta-web/rooms/x?r=room_1&n=${this.playerName}`
                break;
            case 2:
                window.location.href = `https://coloring3d.aestar.com.ua/projects/meta-web/rooms/x?r=room_2&n=${this.playerName}`
                break;
            case 3:
                window.location.href = `https://coloring3d.aestar.com.ua/projects/meta-web/rooms/x?r=room_3&n=${this.playerName}`
                break;
            case 4:
                window.location.href = `https://coloring3d.aestar.com.ua/projects/meta-web/rooms/x?r=room_4&n=${this.playerName}`
                break;
            case 5:
                window.location.href = `https://coloring3d.aestar.com.ua/projects/meta-web/rooms/x?r=room_5&n=${this.playerName}`
                break;
            default:
                break;
        }
    }

    private generateRoomData() {
        for (let i = 1; i <= this.numRooms; i++) {
            this.roomNames.push(`room${i}`);
            this.roomModels.push(require(`../assets/glb/room_${i}.glb`).default);
        }
    }

    private showLoadingScreen() {
        this.engine.displayLoadingUI();
    }

    private hideLoadingScreen() {
        this.engine.hideLoadingUI();
    }

    private loadModels(): Promise<void> {
        const roomPromises = this.roomNames.map((roomName, index) => this.loadRoom(roomName, this.roomModels[index]));
        return Promise.all(roomPromises).then(() => {
            console.log('All rooms and furniture loaded');
        });
    }

    private loadRoom(roomName: string, filePath: string): Promise<void> {
        console.log(roomName, filePath)
        return new Promise((resolve) => {
            BABYLON.SceneLoader.ImportMesh("", "", filePath, this.scene, (meshes) => {
                const roomRoot = meshes[0]
                console.log(roomRoot)
                const roomMeshes = meshes.filter(mesh => mesh.name.startsWith("room"));
                roomRoot.position = new BABYLON.Vector3(0, 2.7, 0);
                roomRoot.scaling.scaleInPlace(ROOM_SCALE);
                const colliderMesh = meshes.find(mesh => mesh.name.startsWith("collider")) as BABYLON.AbstractMesh;
                if (roomMeshes.length > 0) {
                    roomMeshes.forEach(mesh => {
                        // mesh.checkCollisions = true;
                       mesh.isVisible = false
                    }) 
                    this.rooms.set(roomName, roomMeshes);
                }
                if (colliderMesh) {
                    colliderMesh.checkCollisions = true;
                    colliderMesh.isVisible = false;
                    this.colliders.set(roomName, colliderMesh);
                }
                resolve()
            });
            
        })
        
    }


    private switchToNextRoom() {
        this.currentRoomIndex = (this.currentRoomIndex + 1) % this.roomNames.length;
        this.showRoom(this.roomNames[this.currentRoomIndex]);
    }

    public showRoom(roomName: string) {
        this.rooms.forEach((roomMeshes, key) => {
            const isVisible = (key === roomName);
            roomMeshes.forEach(mesh => {
                mesh.isVisible = isVisible
                // mesh.checkCollisions = true;
            });
        });
        this.colliders.forEach((collider, key) => {
            collider.isVisible = (key === roomName);
        });

        if (this.furniture) {
            if (this.roomsWithFurniture.has(roomName)) {
                // this.furniture.parent = this.rooms.get(roomName)[0];
                this.furniture.forEach((obj) => {
                    obj.isVisible = true;
                })
            } else {
                // this.furniture.parent = null;
                this.furniture.forEach((obj) => {
                    obj.isVisible = false;
                })
            }
        }
    }
}