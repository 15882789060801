import * as BABYLON from 'babylonjs';
import {create} from 'nipplejs'
import Hero from './hero';
import eyeImg from '../assets/img/eye.png'

type AnimationType = 'Idle' | 'Walk' | 'Walkback'
type DirectionY = 'forward' | 'backward'

export default class CharacterController{
    private scene: BABYLON.Scene
    private room: any
    private int: number = 0
    private tickrate: number = 30
    private heroRotationSpeed = 0.02;
    private heroSpeed = 0.08;
    private heroSpeedBackwards = 0.06;
    private isCursorRotation: boolean = false
    private currentAnimation: AnimationType = null
    private playerNextPosition: { [playerId: string]: BABYLON.Vector3 } = {};
    private playerNextRotation: { [playerId: string]: BABYLON.Quaternion } = {};
    private character: Hero
    private joystickDirY: 'up' | 'down' | null
    private joystickDirX: 'left' | 'right' | null
    private joystickVector: {x: number, y: number}
    private isJoystickMovement: boolean
    private walkAnim: any
    private walkBackAnim: any
    private idleAnim: any
    constructor(
        scene: BABYLON.Scene,
        room: any,
        playerNextPosition: { [playerId: string]: BABYLON.Vector3 },
        playerNextRotation: { [playerId: string]: BABYLON.Quaternion },
        character: Hero
    ){
        this.scene = scene
        this.room = room
        this.playerNextPosition = playerNextPosition
        this.playerNextRotation = playerNextRotation
        this.character = character
        this.init()
    }

    init(){
        this.walkAnim = this.character.animationGroups.find((animation: any) => animation.name === "Walk");
        this.walkBackAnim = this.character.animationGroups.find((animation: any) => animation.name === "WalkBack");
        this.idleAnim = this.character.animationGroups.find((animation: any) => animation.name === "Idle");
        const changeViewEl = document.querySelector('.changeView')
        changeViewEl.addEventListener('click', () => {
            this.character.changeView()
        })
        document.querySelector('.changeView img').setAttribute('src', eyeImg)
    }

    initJoystic(){
        const joystick = create({
            zone: document.getElementById('static'),
            mode: 'static',
            position: {right: '70px', bottom: '70px'},
            color: '#00ffff',
            size: 70
        });
        this.bindNipple(joystick)
        let animating = true
        this.scene.onBeforeRenderObservable.add(() => {
            if (this.joystickDirY === 'up') {
                this.characterPosition('forward', this.joystickVector.y)
            }
            if (this.joystickDirY === 'down') {
                this.characterPosition('backward', this.joystickVector.y)
            }
            if (this.joystickDirX === 'left') {
                this.characterRotation('left', this.joystickVector.x)
            }
            if (this.joystickDirX === 'right') {
                this.characterRotation('right', this.joystickVector.x)
            }
            
            //Manage animations to be played 
            if (this.isJoystickMovement) {
                if (!animating) {
                    animating = true;
                    if (this.joystickDirY === 'down') {
                        this.animationControl('Walkback')
                    }
                    else {
                        this.animationControl('Walk')
                    }
                }
            } else {
                if (animating) {
                    this.animationControl('Idle')
                    //Stop all animations besides Idle Anim when no key is down
                    this.walkAnim.stop();
                    this.walkBackAnim.stop();
    
                    //Ensure animation are played only once per rendering loop
                    animating = false;
                }
            }
            // let direction: DirectionY  = this.joystickDirY === 'down' ? 'backward' : 'forward'
            // this.manageAnimation(this.isJoystickMovement, direction)
        })
    }

    bindNipple(joystick: any) {
        let this_ = this
        joystick.on('start end', function(evt, data) {
            this_.isJoystickMovement = evt.type === 'start'
            this_.joystickDirY = null
            this_.joystickDirX = null
        }).on('move', function(evt, data) {
            // console.log('move', data)
            if(!data.direction) return
            this_.joystickDirY = data.direction.y
            this_.joystickDirX = data.direction.x
            this_.joystickVector = {x: Math.abs(data.vector.x), y: Math.abs(data.vector.y)}
        }).on('dir:up plain:up dir:left plain:left dir:down ' +
              'plain:down dir:right plain:right',
              function(evt, data) {
          
          
        }
             ).on('pressure', function(evt, data) {
          console.log('pressure')
        });
      }

    updateAvatarPosition(mesh: any){
        this.playerNextPosition[this.room.sessionId].set( mesh.position.x,  mesh.position.y,  mesh.position.z);
        this.room.send("updatePosition", {
            positionX: mesh.position.x,
            positionY: mesh.position.y,
            positionZ: mesh.position.z,
        });
        if (this.int > this.tickrate) {
            this.setPosition(mesh)
        }
        this.int++
    }

    setPosition(mesh: BABYLON.AbstractMesh){
        this.int = 0;
    }

    characterPosition(direction: DirectionY, vectorY: number = 1){
        let speed = direction === 'forward' ? this.heroSpeed * vectorY : -this.heroSpeedBackwards * vectorY
        this.character.mesh.moveWithCollisions(this.character.mesh.forward.scaleInPlace(speed));
        this.updateAvatarPosition(this.character.mesh);
    }

    characterRotation(direction: 'left' | 'right', vectorX: number = 1){
        let speedRotation = direction === 'left' ? this.heroRotationSpeed * vectorX : -this.heroRotationSpeed * vectorX
        this.character.mesh.rotate(BABYLON.Vector3.Up(), speedRotation);
        let currentQuaternion = this.character.mesh.rotationQuaternion;
        let quaternionJsonString = JSON.stringify({
            x: currentQuaternion.x,
            y: currentQuaternion.y,
            z: currentQuaternion.z,
            w: currentQuaternion.w
        });
        this.room.send("updateRotation", {
            quaternion: quaternionJsonString
        });
        this.playerNextRotation[this.room.sessionId] = this.character.mesh.rotationQuaternion
    }

    animationControl(type: AnimationType){
        this.setAnimating(type)

        this.walkBackAnim.start(true, 1.0, this.walkBackAnim.from, this.walkBackAnim.to, false);
        this.room.send('setAnimation', {
            animation: type
        })
    }

    actionsHero(){
        const inputMap: any = {};

        
        this.scene.actionManager = new BABYLON.ActionManager(this.scene);
        this.scene.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnKeyDownTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
        }));
        this.scene.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnKeyUpTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
        }));
 
        let animating = true;
        
         
 
 
         var timeNow = Date.now();
         var timeThen = 0

         this.scene.onKeyboardObservable.add((kbInfo) => {
            switch (kbInfo.type) {
                case BABYLON.KeyboardEventTypes.KEYDOWN:
                    inputMap[kbInfo.event.key] = kbInfo.event.type === "keydown";
                    break;
                case BABYLON.KeyboardEventTypes.KEYUP:
                    inputMap[kbInfo.event.key] = kbInfo.event.type === "keydown";
                    break;
            }

            if(inputMap['c']){
                this.character.changeView()
            }
        });
        
 
         //Rendering loop (executed for everyframe)
         this.scene.onBeforeRenderObservable.add(() => {

                var keydown = false;
                //Manage the movements of the character (e.g. position, direction)
                if (inputMap["w"]) {
                    this.characterPosition('forward')
                    keydown = true;
                }
                if (inputMap["s"]) {
                    this.characterPosition('backward')
                    keydown = true;
                }
                if (inputMap["a"]) {
                    this.characterRotation('left')
                    keydown = true;
                }
                if (inputMap["d"]) {
                    this.characterRotation('right')
                    keydown = true;
                }
                
                //Manage animations to be played 
                if (keydown) {
                    if (!animating) {
                        animating = true;
                        // console.log(direction, this.animating)
                        if (inputMap['s']) {
                            this.animationControl('Walkback')
                        }
                        else {
                            this.animationControl('Walk')
                        }
                    }
                } else {
                    if (animating) {
                        this.animationControl('Idle')
                        //Stop all animations besides Idle Anim when no key is down
                        this.walkAnim.stop();
                        this.walkBackAnim.stop();
        
                        //Ensure animation are played only once per rendering loop
                        animating = false;
                    }
                }
                // let direction: DirectionY  = inputMap['s'] ? 'backward' : 'forward'
                // this.manageAnimation(keydown, direction)
         });
    }

    setAnimating(value: AnimationType){
        this.currentAnimation = value
    }

    activateControls(){
        

        this.scene.onPointerObservable.add((evt) => {
            if (evt.type === BABYLON.PointerEventTypes.POINTERDOWN) {
                this.isCursorRotation = true;
            }
        }, BABYLON.PointerEventTypes.POINTERDOWN);


        this.scene.onPointerObservable.add((evt) => {
            if (evt.type === BABYLON.PointerEventTypes.POINTERUP) {
                this.isCursorRotation = false;

                this.room.send('setAnimation', {
                    animation: this.currentAnimation
                })
            }
        }, BABYLON.PointerEventTypes.POINTERUP);
        this.scene.onPointerObservable.add((evt) => {
            if(this.isCursorRotation) {
                this.room.send('setAnimation', {
                    animation: 'Walk'
                })
                const moveX = evt.event.movementX
                this.character.mesh.rotate(BABYLON.Vector3.Up(), moveX/4 * -this.heroRotationSpeed);
                var currentQuaternion = this.character.mesh.rotationQuaternion;
                var quaternionJsonString = JSON.stringify({
                    x: currentQuaternion.x,
                    y: currentQuaternion.y,
                    z: currentQuaternion.z,
                    w: currentQuaternion.w
                });
                this.room.send("updateRotation", {
                    quaternion: quaternionJsonString
                });
            }
        })
    }
}