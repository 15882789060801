import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

import heroModel from "../assets/glb/girl_v2.glb";

type AnimationType = 'Idle' | 'Walk' | 'Walkback'

export default class Hero {
    public root: any
    public mesh: BABYLON.AbstractMesh;
    public heroRoot: any
    public animationGroups: BABYLON.AnimationGroup[];
    public heroAnimations: Map<string, BABYLON.AnimationGroup>;
    public activeAnimation: AnimationType  | null;
    public playerName: string;
    public firstPersonView: BABYLON.TransformNode
    public thirdPersonView: BABYLON.TransformNode
    private view: 'first' | 'third' = 'third'

    constructor(private scene: BABYLON.Scene, private camera: BABYLON.ArcRotateCamera, playerName: string) {
        this.playerName = playerName
        this.heroAnimations = new Map<string, BABYLON.AnimationGroup>();
    }

    

    async load(): Promise<void> {
        const heroResult = await BABYLON.SceneLoader.ImportMeshAsync(
            "",
            "",
            heroModel,
            this.scene,
            undefined,
            ".glb"
        );
        this.root = heroResult
        this.mesh = heroResult.meshes[0];
        this.firstPersonView = heroResult.transformNodes.find(node => node.name === 'firstPersonView')
        this.thirdPersonView = heroResult.transformNodes.find(node => node.name === 'thirdPersonView')
        
        this.mesh.scaling.scaleInPlace(1.8);
        this.animationGroups = heroResult.animationGroups;
        this.heroAnimations.set('Walk', this.animationGroups.find(animation => animation.name === "Walk"))
        this.heroAnimations.set('Walkback', this.animationGroups.find(animation => animation.name === "WalkBack"))
        this.heroAnimations.set('Idle', this.animationGroups.find(animation => animation.name === "Idle"))
        
    }

    setPosition(position: BABYLON.Vector3): void {
        this.mesh.position = position;
    }

    setRotation(quaternion: BABYLON.Quaternion): void {
        this.mesh.rotationQuaternion = quaternion;
    }

    playAnimation(name: AnimationType): void {
        if(this.activeAnimation === name) return
        const currentAnimation = this.heroAnimations.get(this.activeAnimation)

        if(currentAnimation)
            currentAnimation.stop();
        
        const nextAnimation = this.heroAnimations.get(name);
        if (nextAnimation) {
            nextAnimation.start(true, 1.0, nextAnimation.from, nextAnimation.to, false);
            this.activeAnimation = name;
        }
    }

    cameraMoveTo(target: BABYLON.Vector3, position: BABYLON.Vector3, lowerRadius: number, upperRadius: number){
        this.camera.setTarget(target);
        this.camera.position = position
        this.camera.lowerRadiusLimit = lowerRadius;
        this.camera.upperRadiusLimit = upperRadius;
        // this.scene.stopAnimation(this.camera);
        // this.camera.animations = [];

        // const positionAnimation = new BABYLON.Animation("cameraPositionAnimation", "position", 1000, 
        //     BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        // const positionKeys = [
        //     { frame: 0, value: this.camera.position.clone() },
        //     { frame: 100, value: position }
        // ];
        // positionAnimation.setKeys(positionKeys);

        // const targetAnimation = new BABYLON.Animation("cameraTargetAnimation", "target", 1000, 
        //     BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        // const targetKeys = [
        //     { frame: 0, value: this.camera.getTarget().clone() },
        //     { frame: 100, value: target }
        // ];
        // targetAnimation.setKeys(targetKeys);

        // const lowerRadiusAnimation = new BABYLON.Animation("lowerRadiusAnimation", "lowerRadiusLimit", 1000, 
        //     BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        // const lowerRadiusKeys = [
        //     { frame: 0, value: this.camera.lowerRadiusLimit },
        //     { frame: 100, value: lowerRadius }
        // ];
        // lowerRadiusAnimation.setKeys(lowerRadiusKeys);

        // const upperRadiusAnimation = new BABYLON.Animation("upperRadiusAnimation", "upperRadiusLimit", 1000, 
        //     BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        // const upperRadiusKeys = [
        //     { frame: 0, value: this.camera.upperRadiusLimit },
        //     { frame: 100, value: upperRadius }
        // ];
        // upperRadiusAnimation.setKeys(upperRadiusKeys);

        // // Добавляем анимации в камеру
        // this.camera.animations.push(positionAnimation);
        // this.camera.animations.push(targetAnimation);
        // this.camera.animations.push(lowerRadiusAnimation);
        // this.camera.animations.push(upperRadiusAnimation);

        // // Запускаем анимации
        // this.scene.beginAnimation(this.camera, 0, 100, false);
    }

    changeView(){
        if(this.view === 'third'){
            this.view = 'first'
            this.cameraMoveTo(
                new BABYLON.Vector3(this.firstPersonView.position.x, this.firstPersonView.position.y, this.firstPersonView.position.z + 0.1),
                new BABYLON.Vector3(this.firstPersonView.position.x, this.firstPersonView.position.y, this.firstPersonView.position.z),
                0,
                0
            )
        } else if(this.view === 'first'){
            this.view = 'third'
            this.cameraMoveTo(
                new BABYLON.Vector3(this.thirdPersonView.position.x, this.thirdPersonView.position.y - 0.3, this.thirdPersonView.position.z + 1.5),
                new BABYLON.Vector3(this.thirdPersonView.position.x, this.thirdPersonView.position.y, this.thirdPersonView.position.z),
                2,
                3
            )
        }
    }
}