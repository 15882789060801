import Menu from './menu'
import { getUrlParams } from './utils';

window.addEventListener('DOMContentLoaded', () => {
    const url = new URL(window.location.href);
    const userName = getUrlParams('n')
    const roomName = getUrlParams('r')

    // Create the game using the 'renderCanvas'.
    let menu = new Menu('renderCanvas');

    if(userName) {
        menu.createMenu(false, roomName, userName)
    } else {
        // Create the scene.
        menu.createMenu(true, roomName);
    }
    
});
