import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import * as Colyseus from "colyseus.js";

import Game from './game';
import { createSkyBox } from "./utils";
// console.log(process.env.ENDPOINT);
// const ENV = process.env.NODE_ENV;
const ROOM_NAME = "room_1";
// const ENDPOINT = "ws://localhost:2567";
const ENDPOINT = process.env.ENDPOINT
console.log(`Using endpoint: ${ENDPOINT}`);
// const ENDPOINT = "wss://tutorial-babylonjs-server.glitch.me";

export default class Menu {
    private _canvas: HTMLCanvasElement;
    private _engine: BABYLON.Engine;
    private _scene: BABYLON.Scene;
    private _camera: BABYLON.ArcRotateCamera;
    private _advancedTexture: GUI.AdvancedDynamicTexture;

    // private _colyseus: Client = new Client(ENDPOINT);
    private _colyseus: Colyseus.Client = new Colyseus.Client(ENDPOINT);

    private _errorMessage: GUI.TextBlock = new GUI.TextBlock("errorText");
    private _playerNameInput: GUI.InputText;

    constructor(canvasElement: string) {
        // Create canvas and engine.
        this._canvas = document.getElementById(canvasElement) as HTMLCanvasElement;
        this._engine = new BABYLON.Engine(this._canvas, true);
    }

    createMenu(isNewUser: boolean, roomName: string, _playerName: string = ''): void {
        this._scene = new BABYLON.Scene(this._engine);
        this._camera = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, 1.0, 110, BABYLON.Vector3.Zero(), this._scene);
        this._camera.useAutoRotationBehavior = true;
        this._camera.setTarget(BABYLON.Vector3.Zero());
        this._advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

        createSkyBox(this._scene);


        if(isNewUser){
            const controlBox = new GUI.Rectangle("controlBox");
            controlBox.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
            controlBox.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
            controlBox.height = "100%";
            controlBox.width = "60%";
            controlBox.thickness = 0;

            // Button positioning
            const stackPanel = new GUI.StackPanel();
            stackPanel.isVertical = true;
            stackPanel.height = "50%";
            stackPanel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
            stackPanel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;

            this._playerNameInput = this.createPlayerNameInput();
            
            stackPanel.addControl(this._playerNameInput);

            const createOrJoinButton = this.createMenuButton("createOrJoinGame", "PLAY");
            createOrJoinButton.onPointerClickObservable.add(async () => {
                this.swapControls(false);
                await this.createGame("joinOrCreate", roomName);
            });
            stackPanel.addControl(createOrJoinButton);

            controlBox.addControl(stackPanel);

            this._advancedTexture.addControl(controlBox);

            // FOR TESTING
            const testInput:any = document.getElementById('testPlayerName')
            const testButtonPlay = document.getElementById('testButtonPlay')
            // testInput.addEventListener('input', () => {
            //     console.log('input')
            //     this._playerNameInput.text = testInput.value
            // })
            testButtonPlay.addEventListener('click', async () => {
                console.log('play')
                this._playerNameInput.text = testInput.value
                this.swapControls(false);
                await this.createGame("joinOrCreate", roomName);
            })
        }
        

        this.initLoadingMessageBox();
        this.initErrorMessageBox();
        this.swapLoadingMessageBox(false);
        this.swapErrorMessageBox(false);

        this.doRender();
        if(!isNewUser) {
            this.createGame("joinOrCreate", roomName, _playerName)
        } 
    }

    private createPlayerNameInput(): GUI.InputText {
        const input = new GUI.InputText();
        input.width = "80%";
        input.maxWidth = "300px";
        input.height = "40px";
        input.paddingTop = "10px";
        input.color = "white";
        input.background = "black";
        input.placeholderText = "Enter your name";
        input.fontSize = "20px";
        input.text = "";
        return input;
    }

    private createMenuButton(name: string, text: string): GUI.Button {
        const button = GUI.Button.CreateImageWithCenterTextButton(name, text, "./public/btn-default.png");
        button.width = "45%";
        button.height = "55px";
        button.fontFamily = "Roboto";
        button.fontSize = "6%";
        button.thickness = 0;
        button.paddingTop = "10px"
        button.color = "#c0c0c0";
        return button;
    }

    private swapControls(isEnabled: boolean) {
        for (let btn of this._advancedTexture.getControlsByType("Button")) {
            btn.isEnabled = isEnabled;
        }
    }

    public async createGame(method: string, roomName: string, _playerName: string = ''): Promise<void> {
        let game: Game;
        
        const playerName = _playerName !== '' ? _playerName : this._playerNameInput.text;
        if(playerName === '') return
        try {
            switch (method) {
                case "create":
                    this.swapLoadingMessageBox(true);
                    game = new Game(this._canvas, this._engine, await this._colyseus.create(roomName), playerName);
                    break;
                case "join":
                    this.swapLoadingMessageBox(true);
                    game = new Game(this._canvas, this._engine, await this._colyseus.join(roomName), playerName);
                    break;
                default:
                    this.swapLoadingMessageBox(true);
                    game = new Game(this._canvas, this._engine, await this._colyseus.joinOrCreate(roomName), playerName);
            }
            this._scene.dispose();
            game.bootstrap();
        } catch (error) {
            console.log(error.message)
            this._errorMessage.text = 'No connection!';
            this.swapErrorMessageBox(true);
        }
    }

    private doRender(): void {
        // Run the render loop.
        this._engine.runRenderLoop(() => {
            this._scene.render();
        });

        // The canvas/window resize event handler.
        window.addEventListener('resize', () => {
            this._engine.resize();
        });
    }

    private initLoadingMessageBox() {
        const loadingMessage = new GUI.Rectangle("messageBox");
        loadingMessage.thickness = 0;
        loadingMessage.background = "#131313";

        const loadingText = new GUI.TextBlock("loadingText");
        loadingText.text = "LOADING..."
        loadingText.fontFamily = "Roboto";
        loadingText.color = "#fad836";
        loadingText.fontSize = "30px";
        loadingMessage.addControl(loadingText);

        this._advancedTexture.addControl(loadingMessage);
    }

    private initErrorMessageBox() {
        const errorMessageBox = new GUI.Rectangle("errorMessageBox");
        errorMessageBox.thickness = 0;
        errorMessageBox.background = "#131313";

        this._errorMessage.fontFamily = "Roboto";
        this._errorMessage.color = "#ff1616";
        this._errorMessage.fontSize = "20px";
        this._errorMessage.textWrapping = true;
        errorMessageBox.addControl(this._errorMessage);

        const button = GUI.Button.CreateImageWithCenterTextButton("tryAgainButton", "<- TRY AGAIN", "./public/btn-default.png");
        button.width = "200px";
        button.height = "60px";
        button.fontFamily = "Roboto";
        button.thickness = 0;
        button.color = "#c0c0c0";
        button.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
        button.paddingBottom = "20px";
        button.onPointerClickObservable.add(() => {
            this.swapControls(true);
            this.swapLoadingMessageBox(false);
            this.swapErrorMessageBox(false);
        });
        errorMessageBox.addControl(button);

        this._advancedTexture.addControl(errorMessageBox);
    }

    private swapLoadingMessageBox(isEnabled: boolean) {
        const messageBox = this._advancedTexture.getControlByName("messageBox");
        messageBox.isEnabled = isEnabled;
        messageBox.alpha = isEnabled ? 0.75 : 0;
    }

    private swapErrorMessageBox(isEnabled: boolean) {
        this.swapLoadingMessageBox(false);

        const messageBox = this._advancedTexture.getControlByName("errorMessageBox");
        this._advancedTexture.getControlByName("tryAgainButton").isEnabled = true;
        messageBox.isEnabled = isEnabled;
        messageBox.alpha = isEnabled ? 0.75 : 0;
    }
}
