import * as GUI from 'babylonjs-gui';
import { isMobile } from './utils';

export default class Instruction {
    private room: any
    private playerName: string

    constructor(room: any, playerName: string){
        this.room = room
        this.playerName = playerName
        this.init()
    }
    init(){
        const advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("textUI");

        const playerInfo = new GUI.TextBlock("playerInfo");
        playerInfo.text = `Room name: ${this.room.name}  \nPlayer: ${this.playerName} (ID:${this.room.sessionId})`;
        playerInfo.color = "#eaeaea";
        playerInfo.fontFamily = "Roboto";
        playerInfo.fontSize = 18;
        playerInfo.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        playerInfo.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        playerInfo.paddingTop = "10px";
        playerInfo.paddingLeft = "10px";
        playerInfo.outlineColor = "#000000";
        advancedTexture.addControl(playerInfo);

        if(!isMobile()){
            const instructions = new GUI.TextBlock("instructions");
            instructions.text = "Move w/ WASD keys, look with the mouse \n Key 'C' - change character view \n Key '1...5' - move to room 1...5";
            instructions.color = "#fff000"
            instructions.fontFamily = "Roboto";
            instructions.fontSize = 18;
            instructions.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
            instructions.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
            instructions.paddingBottom = "10px";
            advancedTexture.addControl(instructions);
        }
        

        // back to menu button
        const button = GUI.Button.CreateImageWithCenterTextButton("back", "<- BACK", "./public/btn-default.png");
        button.width = "100px";
        button.height = "50px";
        button.fontFamily = "Roboto";
        button.thickness = 0;
        button.color = "#f8f8f8";
        button.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        button.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        button.paddingTop = "10px";
        button.paddingRight = "10px";
        button.onPointerClickObservable.add(async () => {
            await this.room.leave(true);
        });
        advancedTexture.addControl(button);
    }
}